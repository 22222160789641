import '../styles/navbar.css'
import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import deskLogo from '../Assets/Images/credence_logo-removebg-preview-1-2.png';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from "react-router-dom";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const location = useLocation();
  const isPolicyPage = location.pathname === "/policy";

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Nav.Link to="/" as={RouterLink} onClick={() => updateExpanded(false)}>
          <Navbar.Brand className="d-flex">
            <img src={deskLogo} className="img-fluid-logo logo" alt="brand logo"/>
          </Navbar.Brand>
        </Nav.Link>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            {!isPolicyPage ? (
              <>
                <Nav.Item>
                  <ScrollLink
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    className="nav-link"
                    offset={-70}
                    duration={500}
                    onClick={() => updateExpanded(false)}
                  >
                    <b>HOME</b>
                  </ScrollLink>
                </Nav.Item>

                <Nav.Item>
                  <ScrollLink
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    className="nav-link"
                    offset={-70}
                    duration={500}
                    onClick={() => updateExpanded(false)}
                  >
                    aboutus
                  </ScrollLink>
                </Nav.Item>

                <Nav.Item>
                  <ScrollLink
                    activeClass="active"
                    to="services"
                    spy={true}
                    smooth={true}
                    className="nav-link"
                    offset={-70}
                    duration={500}
                    onClick={() => updateExpanded(false)}
                  >
                    services
                  </ScrollLink>
                </Nav.Item>
              </>
            ) : (
              <Nav.Item>
                <Nav.Link as={RouterLink} to="/" onClick={() => updateExpanded(false)}>
                  BACK TO HOME
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;