import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutImg from '../../Assets/Images/pexels-rdne-7464375.jpg'
import { ImFacebook } from "react-icons/im";
import {
    AiOutlineTwitter,
    AiFillInstagram
  } from "react-icons/ai";
import { Fade, Slide } from 'react-awesome-reveal';


function About() {
  return (
    <Container className='about-container' id='about'>
        <Row className='about-section'>
            <Col className='col-one'>
                <div className='image-container'>
                    <img src={aboutImg} alt='about'/>
                </div>
            </Col>
            <Col className='col-two'>
                <Slide direction='up' damping={0.2} >
                <h2>About Us</h2>
                {/* <h3>CREDENCE MOVERS</h3> */}
                <hr/>
                <Fade >
                <p>Credence Movers is a premier moving service company based in Kenya, dedicated to providing seamless and reliable relocation solutions for both residential and commercial clients. Our name, "Credence," reflects our core values of trust, credibility, and excellence in every move we make. With a team of highly trained professionals and a fleet of well-maintained vehicles, we ensure that your belongings are handled with the utmost care and delivered safely to your new destination. </p></Fade>
                </Slide>

                <ul className="home-about-social-links">
                <Fade cascade damping={0.2} >
                    <li className="social-icons">
                        <a
                            href="https://www.facebook.com/profile.php?id=100007362108142"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour  home-social-icons">
                            <ImFacebook />
                        </a>
                    </li>
                    <li className="social-icons">
                        <a
                            href="https://x.com/credencemovers"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour  home-social-icons">
                            <AiOutlineTwitter />
                        </a>
                    </li>
                    <li className="social-icons">
                        <a
                            href="https://www.instagram.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="icon-colour home-social-icons">
                            <AiFillInstagram />
                        </a>
                    </li>
                    </Fade>
                </ul>

            </Col>
        </Row>
    </Container>

  )
}

export default About