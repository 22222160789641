import React, { useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Col, Container, Row } from 'react-bootstrap'

function Stats() {
  // State for animated values
  const [animatedValues, setAnimatedValues] = useState({
    movedHouses: 0,
    positiveReviews: 0,
    shippedGoods: 0,
    yearsExperience: 0
  });

  // Target values
  const targetValues = {
    movedHouses: 16,
    positiveReviews: 348,
    shippedGoods: 567,
    yearsExperience: 6
  };

  // Animation function
  const animateValues = () => {
    const duration = 2000; // Animation duration in ms
    const startTime = performance.now();
    
    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      
      setAnimatedValues({
        movedHouses: Math.floor(progress * targetValues.movedHouses),
        positiveReviews: Math.floor(progress * targetValues.positiveReviews),
        shippedGoods: Math.floor(progress * targetValues.shippedGoods),
        yearsExperience: Math.floor(progress * targetValues.yearsExperience)
      });
      
      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };
    
    requestAnimationFrame(animate);
  };

  // Format large numbers with commas
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Container className='stats-container'>
        <Row className='stats-section'>
          <Fade className='col-one-fade' onVisibilityChange={(inView) => { if (inView) animateValues(); }}>
            <div className='col-one'>
                
                  <div className='stat-item'>
                      <p>{formatNumber(animatedValues.movedHouses)}k</p>
                      <p>moved houses</p>
                  </div>
                  <div className='stat-item'>
                      <p>{formatNumber(animatedValues.positiveReviews)}k</p>
                      <p>positive reviews</p>
                  </div>
                  <div className='stat-item'>
                      <p>{formatNumber(animatedValues.shippedGoods)}k</p>
                      <p>shipped goods</p>
                  </div>
                  <div className='stat-item'>
                      <p>{animatedValues.yearsExperience}+</p>
                      <p>yrs experience</p>
                  </div>
            </div>
                </Fade>
            <Col className='col-two'>
              <Fade cascade damping={0.2}>
                <h2>Stats</h2>
                <h3>LET NUMBERS SPEAK</h3>
                <hr/>
                <p>See why customers rely on us for stress-free moving. Our numbers reflect our dedication, efficiency, and commitment to excellence.</p>
                <h3>OUR TEAM</h3>
                <hr/>
                <p>Our team is our greatest asset, consisting of experienced movers, logistics experts, and customer service representatives all working together to ensure a smooth relocation experience for our clients. </p>
              </Fade>
            </Col>
        </Row>

        <Row className='vission-mission'>
            
            <div className="feature-card">
              <h3>OUR MISSION</h3>
              <p>To offer top-notch moving services that exceed our clients' expectations by ensuring a smooth, stress-free, and efficient relocation experience.</p>
            </div>
            
            <div className="feature-card">
              <h3>OUR VISSION</h3>
              <p>To be the leading moving service provider in Kenya, recognized for our reliability, professionalism, and commitment to customer satisfaction.</p>
            </div>
            
            <div className="feature-card">
              <h3>COMMITMENT to SUSTAINABILITY</h3>
              <p>At Credence Movers, we are committed to environmental sustainability. We use eco-friendly packing materials and practices to minimize our carbon footprint and promote a greener planet.</p>
            </div>
        </Row>
    </Container>
  )
}

export default Stats