import '../../styles/home.css';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // SEO Optimization
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaArrowRightLong } from "react-icons/fa6";
import landingImage from '../../Assets/Images/admin-ajax-2-2.png';
import About from './About';
import Services from './Services';
import Stats from './Stats';
import WhyUs from './WhyUs';
import QuateDialogue from '../QuoteDialogue/QuateDialogue';
import { Fade, Slide } from 'react-awesome-reveal';

function Home() {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>Credence Movers | Move with Reliability</title>
        <meta name="description" content="Reliable and stress-free moving services. Get a quote today and move with trust and ease." />
        <meta name="keywords" content="moving services, relocation, packers, credence movers, movers, stress-free moving, transport services" />
        <meta name="author" content="Credence Movers" />

        {/* Open Graph (Facebook) Meta Tags */}
        <meta property="og:title" content="Credence Moving Services | Move with Reliability" />
        <meta property="og:description" content="Reliable and stress-free moving services. Get a quote today and move with trust and ease." />
        <meta property="og:image" content={landingImage} />
        <meta property="og:url" content="https://credencemovers.co.ke/" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="Credence Movers | Move with Reliability" />
        <meta name="twitter:description" content="Reliable and stress-free moving services. Get a quote today and move with trust and ease." />
        <meta name="twitter:image" content={landingImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <section>
        {showModal && <QuateDialogue onClose={handleCloseModal} />}

        <Container fluid id="home">
          <Container className='container'>
            <Row className='home-section'>
              <Col className='col-one'>
                <Fade cascade damping={0.2}>
                  <h1>Moving with <br />trust and reliability</h1>
                  <p>Moving services can significantly reduce the stress of relocating, allowing you to focus on settling into your new destination.</p>
                  <Button onClick={() => setShowModal(!showModal)}>
                    Get a Quote <FaArrowRightLong className='icon' />
                  </Button>
                </Fade>
              </Col>

              <Col className='col-two'>
                <Slide cascade direction='right' damping={0.2}>
                  <img src={landingImage} alt="Moving Services" />
                </Slide>
              </Col>
            </Row>
          </Container>

          {/* Additional Sections */}
          <About />
          <Services />
          <Stats />
          <WhyUs />
        </Container>
      </section>
    </>
  );
}

export default Home;
