import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import cleaningIcon from '../../Assets/Images/icons8-cleaning-services-100.png'
import landingImage from '../../Assets/Images/admin-ajax-2-1.png'
import { Fade, Slide } from 'react-awesome-reveal'

function WhyUs() {
  return (
    <Container className='WhyUs-container'>
        <h2>Why Choose Us ?</h2>
        <Row className='WhyUs-section'>
            <Col className='col-one'>
                <Fade cascade damping={0.2}>
                

                <Row className='reasons-item'>
                    <span className='icon-container'>
                        <img src={cleaningIcon} alt='icon'/>
                    </span>
                    <Col className='item-col'>
                        <h3>Experienced Professionals</h3>
                        <p>Our team consists of skilled movers/ cleaners with years of experience in handling all types of relocations and cleaning services .</p>
                    </Col>
                </Row>

                <Row className='reasons-item'>
                    <span className='icon-container'>
                        <img src={cleaningIcon} alt='icon'/>
                    </span>
                    <Col className='item-col'>
                        <h3>Reliable and Timely</h3>
                        <p>We pride ourselves on punctuality and reliability, ensuring your move/cleaning is completed on schedule.  </p>
                    </Col>
                </Row>
                <Row className='reasons-item'>
                    <span className='icon-container'>
                        <img src={cleaningIcon} alt='icon'/>
                    </span>
                    <Col className='item-col'>
                        <h3>Comprehensive Services</h3>
                        <p>From packing to unpacking to cleaning, we offer end-to-end moving solutions tailored to your needs. </p>
                    </Col>
                </Row>
                <Row className='reasons-item'>
                    <span className='icon-container'>
                        <img src={cleaningIcon} alt='icon'/>
                    </span>
                    <Col className='item-col'>
                        <h3>Safety First</h3>
                        <p>We use high-quality packing materials and advanced equipment to protect your belongings during transit. </p>
                    </Col>
                </Row>
                <Row className='reasons-item'>
                    <span className='icon-container'>
                        <img src={cleaningIcon} alt='icon'/>
                    </span>
                    <Col className='item-col'>
                        <h3>Customer-Centric Approach</h3>
                        <p>Customer-Centric Approach: Your satisfaction is our top priority. We provide personalized services to meet your specific requirements.  </p>
                    </Col>
                </Row>
                <Row className='reasons-item'>
                    <span className='icon-container'>
                        <img src={cleaningIcon} alt='icon'/>
                    </span>
                    <Col className='item-col'>
                        <h3>Transparent Pricing</h3>
                        <p>No hidden fees or surprises. We offer clear and competitive pricing for all our services.   </p>
                    </Col>
                </Row>
                </Fade>
            </Col>
            <Col className='col-one'>
                <Slide cascade direction='right' damping={0.2}>
                    <img src={landingImage} alt='' />
                </Slide>
            </Col>

        </Row>
        <Row className='map'>
            <iframe className='iframe' style={{height:"100%",width:"100%"}} title='Credence movers' frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Total+plaza+Bamburi,+Mombasa,+Kenya&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
        </Row>
    </Container>
  )
}

export default WhyUs