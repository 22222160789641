import React, { useState, useRef, useEffect } from 'react';
import '../../styles/dialogue.css';
import { Container, Row, Button, Col } from 'react-bootstrap';
import { IoIosClose } from "react-icons/io";
import { IoHomeOutline, IoHome } from "react-icons/io5";
import { HiOutlineOfficeBuilding, HiOfficeBuilding } from "react-icons/hi";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdLocationOn } from "react-icons/md";
import { FaTruckMoving } from "react-icons/fa";
import { MdEmail, MdPhone, MdCalendarToday, MdAccessTime } from "react-icons/md";

function QuoteDialogue({ onClose }) {
  const [step, setStep] = useState(1); // 1: selection, 2: location inputs, 3: quote details
  const [moveType, setMoveType] = useState(null); // 'home' or 'office'
  const [showMap, setShowMap] = useState(false);
  const [currentMapFor, setCurrentMapFor] = useState(''); // 'origin' or 'destination'
  const [mapSearchQuery, setMapSearchQuery] = useState('');
  const [mapSuggestions, setMapSuggestions] = useState([]);
  const [showMapSuggestions, setShowMapSuggestions] = useState(false);
  const [isMapSearchLoading, setIsMapSearchLoading] = useState(false);
  const [formData, setFormData] = useState({
    origin: '',
    originLat: '',
    originLng: '',
    destination: '',
    destinationLat: '',
    destinationLng: ''
  });
  const [quoteDetails, setQuoteDetails] = useState({
    distance: 0,
    price: 0
  });
  
  // 1: selection, 2: location inputs, 3: quote details, 4: contact details, 5: thank you
  const [houseType, setHouseType] = useState('');
  const [contactDetails, setContactDetails] = useState({
    name: '',
    email: '',
    phone: '',
    moveDate: '',
    moveTime: ''
  });
  
  // New state for location suggestions
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsFor, setSuggestionsFor] = useState(''); // 'origin' or 'destination'
  const [isLoading, setIsLoading] = useState(false);
  
  const mapRef = useRef(null);
  const leafletMapRef = useRef(null);
  const markerRef = useRef(null);
  const searchInputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const originInputRef = useRef(null);
  const destinationInputRef = useRef(null);

  // House type options
  const houseTypes = [
    { value: 'bedsitter', label: 'Bedsitter' },
    { value: '1bedroom', label: '1 Bedroom' },
    { value: '2bedroom', label: '2 Bedrooms' },
    { value: '3bedroom', label: '3 Bedrooms' },
    { value: '4plus', label: '4+ Bedrooms' }
  ];

  // Time options
  const timeOptions = [
    '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', 
    '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', 
    '04:00 PM', '05:00 PM'
  ];

  // Handle contact details change
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle house type selection
  const handleHouseTypeSelect = (type) => {
    setHouseType(type);
  };

  // Validate phone number (10 digits)
  const validatePhone = (phone) => {
    return /^\d{10}$/.test(phone);
  };

  // Validate email
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Debounce function to limit API calls
  const debounce = (func, delay) => {
    let timeoutId;
    return function(...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // Function to fetch location suggestions
  const fetchSuggestions = async (query, for_location) => {
    if (!query.trim() || query.length < 3) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&limit=5`
      );
      const data = await response.json();
      setSuggestions(data);
      setSuggestionsFor(for_location);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Create debounced version of fetchSuggestions
  const debouncedFetchSuggestions = useRef(
    debounce((query, for_location) => fetchSuggestions(query, for_location), 300)
  ).current;

  const handleClose = () => {
    // Reset state and close dialog
    setMoveType(null);
    setStep(1);
    setFormData({
      origin: '',
      originLat: '',
      originLng: '',
      destination: '',
      destinationLat: '',
      destinationLng: ''
    });
    setQuoteDetails({
      distance: 0,
      price: 0
    });
    setSuggestions([]);
    setShowSuggestions(false);
    // Add code to close the dialog component here
    // For example: props.onClose();
    if (onClose) onClose();
  };

  const handleSelectionClick = (type) => {
    setMoveType(type);
  };

  // Calculate distance between two points using Haversine formula
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in km
    return distance;
  };

  // Calculate price based on distance and move type
  const calculatePrice = (distance, type) => {
    // Base pricing: 1000 shillings per km for home, 1500 for office
    const basePricePerKm = type === 'home' ? 1000 : 1500;
    let price = distance * basePricePerKm;
    
    // Minimum price
    const minPrice = type === 'home' ? 3000 : 5000;
    price = Math.max(price, minPrice);
    
    // Add 16% VAT
    price = price * 1.16;
    
    // Round to nearest 100
    return Math.ceil(price / 100) * 100;
  };

   // Update the handleNextClick function to include new steps
   const handleNextClick = () => {
    if (step === 1) {
      if (!moveType) return;
      setStep(2);
    } else if (step === 2) {
      if (!formData.originLat || !formData.originLng || !formData.destinationLat || !formData.destinationLng) {
        return;
      }
      
      const distance = calculateDistance(
        parseFloat(formData.originLat),
        parseFloat(formData.originLng),
        parseFloat(formData.destinationLat),
        parseFloat(formData.destinationLng)
      );
      
      const price = calculatePrice(distance, moveType);
      
      setQuoteDetails({
        distance: distance.toFixed(2),
        price: price
      });
      
      setStep(3);
    } else if (step === 3) {
      // Move to contact details step
      setStep(4);
    } else if (step === 4) {
      handleFinalSubmit();
    }
  };

  const handleFinalSubmit = () => {
    // Validate contact details
    if (!validateEmail(contactDetails.email)) {
      alert('Please enter a valid email address');
      return;
    }
    
    if (!validatePhone(contactDetails.phone)) {
      alert('Please enter a valid 10-digit phone number');
      return;
    }
    
    if (!contactDetails.moveDate || !contactDetails.moveTime) {
      alert('Please select move date and time');
      return;
    }
    
    if (moveType === 'home' && !houseType) {
      alert('Please select house type');
      return;
    }
    
    // Here you would typically submit all the data to your backend
    console.log("Final submission:", { 
      moveType, 
      ...formData,
      distance: quoteDetails.distance,
      price: quoteDetails.price,
      houseType: moveType === 'home' ? houseType : null,
      ...contactDetails
    });
    
    // Proceed to thank you step
    setStep(5);
  };

  const handleBackClick = () => {
    setStep(step - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Fetch suggestions for location inputs
    if (name === 'origin' || name === 'destination') {
      debouncedFetchSuggestions(value, name);
    }
  };

  const handleInputFocus = (location) => {
    // If there's already a value, show suggestions on focus
    if (formData[location] && formData[location].length >= 3) {
      debouncedFetchSuggestions(formData[location], location);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const { lat, lon, display_name } = suggestion;
    
    if (suggestionsFor === 'origin') {
      setFormData(prev => ({
        ...prev,
        origin: display_name,
        originLat: lat,
        originLng: lon
      }));
    } else if (suggestionsFor === 'destination') {
      setFormData(prev => ({
        ...prev,
        destination: display_name,
        destinationLat: lat,
        destinationLng: lon
      }));
    }
    
    // Clear suggestions after selection
    setShowSuggestions(false);
  };

  const handleSelectLocation = (locationType) => {
    setCurrentMapFor(locationType);
    setShowMap(true);
    
    // Wait for DOM update before initializing the map
    setTimeout(() => {
      // Load Leaflet if not already loaded
      if (!window.L) {
        const script = document.createElement('script');
        script.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';
        script.integrity = 'sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=';
        script.crossOrigin = '';
        script.onload = initMap;
        document.head.appendChild(script);
        
        // Add Leaflet CSS if not already loaded
        if (!document.querySelector('link[href*="leaflet.css"]')) {
          const leafletCSS = document.createElement('link');
          leafletCSS.rel = 'stylesheet';
          leafletCSS.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css';
          document.head.appendChild(leafletCSS);
        }
      } else {
        initMap();
      }
    }, 100);
  };

  const initMap = () => {
    if (!window.L || !mapRef.current || leafletMapRef.current) return;
    
    // Initialize the map with default view
    const defaultPosition = [0, 0];
    const map = window.L.map(mapRef.current, {
      center: defaultPosition,
      zoom: 2
    });
    
    // Add tile layer (OpenStreetMap)
    window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    
    // Create a custom icon for the marker (using SVG)
    const svgIcon = window.L.divIcon({
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36">
          <path fill="#3ea701" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
        </svg>
      `,
      className: 'custom-marker-icon',
      iconSize: [36, 36],
      iconAnchor: [18, 36],
      popupAnchor: [0, -36]
    });
    
    // Create a marker with the custom icon
    const marker = window.L.marker(defaultPosition, {
      icon: svgIcon,
      draggable: true
    }).addTo(map);
    
    // Store references
    leafletMapRef.current = map;
    markerRef.current = marker;
    
    // Handle marker drag events
    marker.on('dragend', async function(e) {
      const position = marker.getLatLng();
      updateFormLocationData({
        lat: position.lat,
        lng: position.lng
      });
      
      // Get address from coordinates using Nominatim
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${position.lat}&lon=${position.lng}&format=json`);
        const data = await response.json();
        if (data && data.display_name) {
          updateFormLocationData({
            lat: position.lat,
            lng: position.lng
          }, data.display_name);
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    });
    
    // Handle map click events
    map.on('click', async function(e) {
      const position = e.latlng;
      marker.setLatLng(position);
      
      updateFormLocationData({
        lat: position.lat,
        lng: position.lng
      });
      
      // Get address from coordinates using Nominatim
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${position.lat}&lon=${position.lng}&format=json`);
        const data = await response.json();
        if (data && data.display_name) {
          updateFormLocationData({
            lat: position.lat,
            lng: position.lng
          }, data.display_name);
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    });
    
    // Setup search functionality using Nominatim
    if (searchInputRef.current) {
      const searchInput = searchInputRef.current;
      
      // Function to handle search
      const handleSearch = async () => {
        const searchValue = searchInput.value.trim();
        if (!searchValue) return;
        
        try {
          const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(searchValue)}&format=json&limit=1`);
          const data = await response.json();
          
          if (data && data.length > 0) {
            const result = data[0];
            const latlng = {
              lat: parseFloat(result.lat),
              lng: parseFloat(result.lon)
            };
            
            marker.setLatLng([latlng.lat, latlng.lng]);
            map.setView([latlng.lat, latlng.lng], 15);
            
            updateFormLocationData(latlng, result.display_name);
          } else {
            console.warn("Location not found.");
          }
        } catch (error) {
          console.error("Error searching location:", error);
        }
      };
      
      // Add event listeners for search input
      searchInput.addEventListener('keypress', function(e) {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleSearch();
        }
      });
      
      // Add a search button beside the input
      const searchButton = document.createElement('button');
      searchButton.textContent = 'Search';
      searchButton.className = 'map-search-button';
      searchButton.onclick = function(e) {
        e.preventDefault();
        handleSearch();
      };
      
      searchInput.parentNode.appendChild(searchButton);
    }
    
    // Try to center the map based on user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = [
            position.coords.latitude,
            position.coords.longitude
          ];
          
          marker.setLatLng(userLocation);
          map.setView(userLocation, 10);
        },
        () => {
          // If geolocation failed, do nothing (map stays at default view)
        }
      );
    }
    
    // Ensure the map renders correctly
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  };

  const handleMapSearchChange = (e) => {
    const query = e.target.value;
    setMapSearchQuery(query);
    
    if (query.length >= 3) {
      debouncedFetchMapSuggestions(query);
    } else {
      setMapSuggestions([]);
      setShowMapSuggestions(false);
    }
  };
  
  const fetchMapSuggestions = async (query) => {
    if (!query.trim()) {
      setMapSuggestions([]);
      setShowMapSuggestions(false);
      return;
    }
  
    setIsMapSearchLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&limit=5`
      );
      const data = await response.json();
      setMapSuggestions(data);
      setShowMapSuggestions(true);
    } catch (error) {
      console.error('Error fetching map location suggestions:', error);
      setMapSuggestions([]);
    } finally {
      setIsMapSearchLoading(false);
    }
  };
  
  const debouncedFetchMapSuggestions = useRef(
    debounce((query) => fetchMapSuggestions(query), 300)
  ).current;

  const handleMapSuggestionClick = (suggestion) => {
    const { lat, lon, display_name } = suggestion;
    
    // Update the map view and marker position
    if (leafletMapRef.current && markerRef.current) {
      const latlng = [parseFloat(lat), parseFloat(lon)];
      markerRef.current.setLatLng(latlng);
      leafletMapRef.current.setView(latlng, 15);
      
      // Update the form data with properly formatted position
      updateFormLocationData({
        lat: parseFloat(lat),
        lng: parseFloat(lon)
      }, display_name);
    }
    
    // Clear suggestions
    setMapSearchQuery(display_name);
    setShowMapSuggestions(false);
  };

  const updateFormLocationData = (position, address = '') => {
    // Ensure position has lat and lng properties and they're numbers
    const lat = typeof position.lat === 'number' ? position.lat : parseFloat(position.lat);
    const lng = typeof position.lng === 'number' ? position.lng : parseFloat(position.lng);
  
    if (currentMapFor === 'origin') {
      setFormData(prevData => ({
        ...prevData,
        originLat: lat.toFixed(6),
        originLng: lng.toFixed(6),
        origin: address || prevData.origin
      }));
    } else if (currentMapFor === 'destination') {
      setFormData(prevData => ({
        ...prevData,
        destinationLat: lat.toFixed(6),
        destinationLng: lng.toFixed(6),
        destination: address || prevData.destination
      }));
    }
  };
  
  const handleConfirmLocation = () => {
    setShowMap(false);
    
    // Clean up map to prevent memory leaks
    if (leafletMapRef.current) {
      leafletMapRef.current.remove();
      leafletMapRef.current = null;
      markerRef.current = null;
    }
  };

  // Format price with commas as thousands separator
  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle clicks outside suggestions to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current && 
        !suggestionsRef.current.contains(event.target) &&
        event.target !== originInputRef.current &&
        event.target !== destinationInputRef.current
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Add to your existing useEffect for click outside handling
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) &&
        event.target !== originInputRef.current &&
        event.target !== destinationInputRef.current &&
        event.target !== searchInputRef.current
      ) {
        setShowSuggestions(false);
        setShowMapSuggestions(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      if (leafletMapRef.current) {
        leafletMapRef.current.remove();
        leafletMapRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    // Disable scrolling on body when dialog opens
    document.body.classList.add('dialog-open');
    
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.classList.remove('dialog-open');
    };
  }, []);

  // Add new step for contact details
  const renderContactDetailsStep = () => (
    <>
      <Row className="dialogue-header">
        <h3>Contact Details</h3>
        <button className="close-btn" onClick={handleClose}><IoIosClose/></button>
      </Row>
      <Col className="dialogue-content">
        {moveType === 'home' && (
          <>
            <h4 style={{textAlign: 'left'}}>House Type</h4>
            <div className="house-type-container">
              {houseTypes.map((type) => (
                <div
                  key={type.value}
                  className={`house-type-option ${houseType === type.value ? 'selected' : ''}`}
                  onClick={() => handleHouseTypeSelect(type.value)}
                >
                  {type.label}
                </div>
              ))}
            </div>
          </>
        )}
        
        <div className="contact-form">
          <div className="form-group">
            <label style={{textAlign: 'left'}}>Full Name</label>
            <input
              type="text"
              name="name"
              value={contactDetails.name}
              onChange={handleContactChange}
              placeholder="Your full name"
              required
            />
          </div>
          
          <div className="form-group">
            <label style={{textAlign: 'left'}}>Email Address</label>
            <div className="location-input-group">
              <input
                type="email"
                name="email"
                value={contactDetails.email}
                onChange={handleContactChange}
                placeholder="your@email.com"
                required
              />
              <MdEmail className="location-picker-btn" />
            </div>
          </div>
          
          <div className="form-group">
            <label style={{textAlign: 'left'}}>Phone Number</label>
            <div className="location-input-group">
              <input
                type="tel"
                name="phone"
                value={contactDetails.phone}
                onChange={handleContactChange}
                placeholder="0712345678"
                maxLength="10"
                required
              />
              <MdPhone className="location-picker-btn" />
            </div>
          </div>
          
          <div className="form-group">
            <label style={{textAlign: 'left'}}>Preferred Moving Date</label>
            <div className="location-input-group">
              <input
                type="date"
                name="moveDate"
                value={contactDetails.moveDate}
                onChange={handleContactChange}
                min={new Date().toISOString().split('T')[0]}
                required
              />
            </div>
          </div>
          
          <div className="form-group">
            <label style={{textAlign: 'left'}}>Preferred Moving Time</label>
            <div className="location-input-group">
              <select
                name="moveTime"
                value={contactDetails.moveTime}
                onChange={handleContactChange}
                required
              >
                <option value="">Select time</option>
                {timeOptions.map((time, index) => (
                  <option key={index} value={time}>{time}</option>
                ))}
              </select>
              <MdAccessTime className="location-picker-btn" />
            </div>
          </div>
        </div>
        
        <div className="buttons-container">
          <Button className="back-btn" onClick={handleBackClick}>
            Back
          </Button>
          <Button 
            className="confirm-btn"
            onClick={handleNextClick}
            disabled={
              !contactDetails.name || 
              !contactDetails.email || 
              !contactDetails.phone || 
              !contactDetails.moveDate || 
              !contactDetails.moveTime ||
              (moveType === 'home' && !houseType)
            }
          >
            Finalize <FaArrowRightLong/>
          </Button>
        </div>
      </Col>
    </>
  );

  // Add new step for thank you message
  const renderThankYouStep = () => (
    <>
      <Row className="dialogue-header">
        <h3>Thank You!</h3>
        <button className="close-btn" onClick={handleClose}><IoIosClose/></button>
      </Row>
      <Row className="dialogue-content">
        <div className="thank-you-message">
          <FaTruckMoving size={60} color="var(--secondary-color)" />
          <h3>Thank you for your request</h3>
          <p>
            Our team will be contacting you shortly to {moveType === 'home' ? 'conduct a survey of your premises' : 'provide a quotation'}.
            We appreciate your business and look forward to serving you.
          </p>
          
          <div className="thank-you-buttons">
            <Button className="back-btn" onClick={handleClose}>
              Close
            </Button>
            <Button 
              className="confirm-btn"
              onClick={() => {
                // Reset all state and start over
                setStep(1);
                setMoveType(null);
                setFormData({
                  origin: '',
                  originLat: '',
                  originLng: '',
                  destination: '',
                  destinationLat: '',
                  destinationLng: ''
                });
                setQuoteDetails({
                  distance: 0,
                  price: 0
                });
                setHouseType('');
                setContactDetails({
                  name: '',
                  email: '',
                  phone: '',
                  moveDate: '',
                  moveTime: ''
                });
              }}
            >
              Start Over
            </Button>
          </div>
        </div>
      </Row>
    </>
  );

  return (
    <Container fluid className='dialogue-section'>
      <Container className='dialogue-container'>
        {/* Step 1: Selection */}
        {step === 1 && (
          <>
            <Row className="dialogue-header">
              <h3>Select Your Move</h3>
              <button className="close-btn" onClick={handleClose}><IoIosClose/></button>
            </Row>
            <Row className="dialogue-content">
              <div className='select-container'>
                <span 
                  className={moveType === 'home' ? 'selected' : ''}
                  onClick={() => handleSelectionClick('home')}
                >
                  {moveType === 'home' ? <IoHome/> : <IoHomeOutline/>}
                  <p>Home</p>
                </span>
                <span 
                  className={moveType === 'office' ? 'selected' : ''}
                  onClick={() => handleSelectionClick('office')}
                >
                  {moveType === 'office' ? <HiOfficeBuilding/> : <HiOutlineOfficeBuilding/>}
                  <p>Office</p>
                </span>
              </div>
              <Button 
                className="next-btn"
                onClick={handleNextClick}
                disabled={!moveType}
              >
                Next <FaArrowRightLong/>
              </Button>
            </Row>
          </>
        )}

        {/* Step 2: Location Inputs */}
        {step === 2 && (
          <>
            <Row className="dialogue-header">
              <h3>{moveType === 'home' ? 'Home Move Details' : 'Office Move Details'}</h3>
              <button className="close-btn" onClick={handleClose}><IoIosClose/></button>
            </Row>
            <Row className="dialogue-content">
              <div className='location-inputs-container'>
                <div className="location-input-group">
                  <input
                    ref={originInputRef}
                    type="text"
                    name="origin"
                    placeholder='Origin Address'
                    value={formData.origin}
                    onChange={handleInputChange}
                    onFocus={() => handleInputFocus('origin')}
                  />
                  <button 
                    type="button" 
                    className="location-picker-btn"
                    onClick={() => handleSelectLocation('origin')}
                    title="Select origin on map"
                  >
                    <MdLocationOn />
                  </button>
                  
                  {/* Origin Location suggestions dropdown */}
                  {showSuggestions && suggestionsFor === 'origin' && (
                    <div className="suggestions-dropdown" ref={suggestionsRef}>
                      {isLoading ? (
                        <div className="suggestion-item loading">Loading...</div>
                      ) : suggestions.length > 0 ? (
                        suggestions.map((suggestion, index) => (
                          <div 
                            key={index} 
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion.display_name}
                          </div>
                        ))
                      ) : (
                        <div className="suggestion-item no-results">No locations found</div>
                      )}
                    </div>
                  )}
                </div>

                <div className="location-input-group">
                  <input
                    ref={destinationInputRef}
                    type="text"
                    name="destination"
                    placeholder='Destination Address'
                    value={formData.destination}
                    onChange={handleInputChange}
                    onFocus={() => handleInputFocus('destination')}
                  />
                  <button 
                    type="button" 
                    className="location-picker-btn"
                    onClick={() => handleSelectLocation('destination')}
                    title="Select destination on map"
                  >
                    <MdLocationOn />
                  </button>
                  
                  {/* Destination Location suggestions dropdown */}
                  {showSuggestions && suggestionsFor === 'destination' && (
                    <div className="suggestions-dropdown" ref={suggestionsRef}>
                      {isLoading ? (
                        <div className="suggestion-item loading">Loading...</div>
                      ) : suggestions.length > 0 ? (
                        suggestions.map((suggestion, index) => (
                          <div 
                            key={index} 
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            {suggestion.display_name}
                          </div>
                        ))
                      ) : (
                        <div className="suggestion-item no-results">No locations found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="buttons-container">
                <Button className="back-btn" onClick={handleBackClick}>
                  Back
                </Button>
                <Button 
                  className="confirm-btn"
                  onClick={handleNextClick}
                  disabled={!formData.origin || !formData.destination}
                >
                  Get Quote <FaArrowRightLong/>
                </Button>
              </div>
            </Row>
          </>
        )}

        {/* Step 3: Quote Details */}
        {step === 3 && (
          <>
            <Row className="dialogue-header">
              <h3>Your Moving Quote</h3>
              <button className="close-btn" onClick={handleClose}><IoIosClose/></button>
            </Row>
            <Col className="dialogue-content">
              <div className="quote-details-container">
                <div className="quote-type">
                  <h4>{moveType === 'home' ? 'Home Move' : 'Office Move'}</h4>
                </div>
                <div className="quote-route">
                  <p><strong>From:</strong> {formData.origin}</p>
                  <p><strong>To:</strong> {formData.destination}</p>
                </div>
                <div className="quote-distance">
                  <p><strong>Distance:</strong> {quoteDetails.distance} km</p>
                  <p><strong>Price:</strong> Ksh{formatPrice(quoteDetails.price)}</p>
                  <p>* Price includes 16% VAT</p>
                </div>
              </div>
              <div className="buttons-container">
                <Button className="back-btn" onClick={handleBackClick}>
                  Back
                </Button>
                <Button 
                  className="confirm-btn"
                  onClick={handleNextClick}
                >
                  Continue <FaArrowRightLong/>
                </Button>
              </div>
            </Col>
          </>
        )}

        {/* Step 4: Contact Details */}
        {step === 4 && renderContactDetailsStep()}

        {/* Step 5: Thank You */}
        {step === 5 && renderThankYouStep()}

        {/* Map Modal */}
        {showMap && (
          <div className="map-modal">
            <div className="map-container">
              <div className="map-header">
                <h4>Select {currentMapFor === 'origin' ? 'Origin' : 'Destination'} Location</h4>
                <IoIosClose className='close-map-btn' onClick={handleConfirmLocation} />
              </div>
              <div className="map-search">
                <input
                    ref={searchInputRef}
                    type="text"
                    placeholder="Search for a location"
                    value={mapSearchQuery}
                    onChange={handleMapSearchChange}
                />
                
                {/* Map search suggestions dropdown */}
                {showMapSuggestions && (
                    <div className="suggestions-dropdown">
                    {isMapSearchLoading ? (
                        <div className="suggestion-item loading">Loading...</div>
                    ) : mapSuggestions.length > 0 ? (
                        mapSuggestions.map((suggestion, index) => (
                        <div 
                            key={index} 
                            className="suggestion-item"
                            onClick={() => handleMapSuggestionClick(suggestion)}
                        >
                            {suggestion.display_name}
                        </div>
                        ))
                    ) : (
                        <div className="suggestion-item no-results">No locations found</div>
                    )}
                    </div>
                )}
                </div>
              <div className="map-view" ref={mapRef}></div>
              <div className="map-footer">
                <p>
                  Selected coordinates: 
                  {currentMapFor === 'origin' 
                    ? `${formData.originLat}, ${formData.originLng}` 
                    : `${formData.destinationLat}, ${formData.destinationLng}`
                  }
                </p>
                <p>
                  Address: 
                  {currentMapFor === 'origin' 
                    ? formData.origin 
                    : formData.destination
                  }
                </p>
                <button className="confirm-location-btn" onClick={handleConfirmLocation}>
                  Confirm Location
                </button>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Container>
  );

  
}

export default QuoteDialogue;