import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import cleaningIcon from '../../Assets/Images/icons8-cleaning-services-100.png'
import carCleaning from '../../Assets/Images/icons8-car-cleaning-96.png'
import residentialIcon from '../../Assets/Images/icons8-house-96.png'
import officeIcon from '../../Assets/Images/icons8-office-100.png'
import storageUnit from '../../Assets/Images/icons8-open-garage-door-100.png'
import shipping from '../../Assets/Images/icons8-overseas-shipping-64.png'
import { Fade, Slide } from 'react-awesome-reveal'

function Services() {
  return (
    <Container className='services-container' id='services'>
        <Row className='services-section'>
            <Col className='col-one'>
                <Fade cascade damping={0.2}>
                    <h2>Services</h2>
                    <h3>PRECISE AND HARDWORKING</h3>
                    <hr/>
                    <p>We aim To offer top-notch moving services that exceed our clients' expectations by ensuring a smooth, stress-free, and efficient relocation experience. </p>
                </Fade>
            </Col>
            <Row className='row-one'>
                <Slide className='row-one row' direction='right' cascade damping={0.3}>
                    <Col className='service-item'>
                        <img src={residentialIcon} alt='residential moving'/>
                        <br/>
                        <h3>Residential Moving</h3>
                        <hr/>
                        <ul>
                            <li>Local and long-distance moving</li>
                            <li>Packing and unpacking services </li>
                            <li>Furniture disassembly and reassembly</li>
                            <li>Specialized handling for fragile items</li>
                        </ul>
                    </Col>
                    <Col className='service-item'>
                        <img src={officeIcon} alt='residential moving'/>
                        <br/>
                        <h3>Commercial Moving</h3>
                        <hr/>
                        <ul>
                            <li>Office relocation </li>
                            <li>Corporate moves  </li>
                            <li>Equipment and machinery transport </li>
                            <li>IT and electronics relocation </li>
                        </ul>
                    </Col>
                </Slide>

            </Row>

        </Row>
        <Row className='services-section-two'>
                <Slide className='row-one row' direction='up' cascade damping={0.2}>
            <Row className='row-one'>
                    <Col className='service-item'>
                        <img src={storageUnit} alt='residential moving'/>
                        <br/>
                        <h3>Specialized Services</h3>
                        <hr/>
                        <ul>
                            <li>Storage solutions </li>
                            <li>Packing supplies  </li>
                            <li>cctv installation /nanny cams </li>
                        </ul>
                    </Col>


                    <Col className='service-item'>
                        <img src={shipping} alt='residential moving'/>
                        <br/>
                        <h3>International Moving</h3>
                        <hr/>
                        <ul>
                            <li>Coordination of international relocations  </li>
                            <li>Customs clearance assistance  </li>
                            <li>Overseas shipping  </li>
                            <li>Destination services </li>
                        </ul>
                    </Col>


                    <Col className='service-item'>
                        <img src={carCleaning} alt='residential moving'/>
                        <br/>
                        <h3>cleaning services</h3>
                        <hr/>
                        <ul>
                            
                            <li>Stain and odor removal </li>
                            <li>Pet stain treatment </li>
                            <li>Area rug cleaning  </li>
                            <li>Move in / move out cleaning </li>
                            <li>Residential carpet cleaning  </li>
                        </ul>
                    </Col>


                    <Col className='service-item'>
                        <img src={cleaningIcon} alt='residential moving'/>
                        <br/>
                        <h3>Commercial Carpet Cleaning</h3>
                        <hr/>
                        <ul>
                            <li>Office and retail carpet cleaning </li>
                            <li>Industrial carpet maintenance  </li>
                            <li>Deep carpet cleaning </li>
                        </ul>
                    </Col>
            </Row>
                </Slide>
        </Row>

    </Container>
  )
}

export default Services