import React from 'react'
import '../styles/footer.css'
import { Col, Container, Row } from 'react-bootstrap'
import deskLogo from '../Assets/Images/credence_logo-removebg-preview-1-2.png';
import { ImFacebook } from "react-icons/im";
import {
    AiOutlineTwitter,
    AiFillInstagram
  } from "react-icons/ai";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className='footer-container'>
      <Row className='footer-section'>
        <Col className='col-one'>
            <img src={deskLogo} className="img-fluid-logo logo" alt="brand logo"/>
            <p>At Credence Movers, we are committed to environmental sustainability. We use eco-friendly packing materials and practices to minimize our carbon footprint and promote a greener planet.</p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                  <a
                      href="https://www.facebook.com/profile.php?id=100007362108142"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons">
                      <ImFacebook />
                  </a>
              </li>
              <li className="social-icons">
                  <a
                      href="https://x.com/credencemovers"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons">
                      <AiOutlineTwitter />
                  </a>
              </li>
              <li className="social-icons">
                  <a
                      href="https://www.instagram.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour home-social-icons">
                      <AiFillInstagram />
                  </a>
              </li>
          </ul>
        </Col>

        <Col className='col-two'>
          <h3>REACH US ON </h3><hr/>
          <p>Phone: +254 711 252181/ 0774930884</p>
          <p>Email: info@credencemovers.co.ke</p>
          <p>Website: www.credencemovers.co.ke</p>
          <p>Address: Total plaza Bamburi, Mombasa, Kenya</p>
        </Col>
        <Col className='col-two'>
          <h3>SUBSCRIBE NOW </h3><hr/>
          <input type='email' placeholder='your email' name='email'/>
          <input type='submit' value='subsribe'/>
        </Col>
      </Row>
      <Row className='footer'>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} Credence Movers</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Designed and Developed by BigBrainz solutions</h3>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer